<template>
  <div>
    <div class="search">
      <div class="searchFl">
        <el-input class="searchInput" clearable v-model="searchQuery.bill_no" size="small" placeholder="请输入账单号"></el-input>
        <el-input class="searchInput" clearable v-model="searchQuery.job_no" size="small" placeholder="请输入委托编号"></el-input>
        <el-input class="searchInput" clearable v-model="searchQuery.receipt_no" size="small" placeholder="请输入发票编号"></el-input>
        
        <el-date-picker
          :clearable='true'
          style="margin-left:20px;"
          v-model="searchQuery.time"
          type="daterange"
          size="small"
          range-separator="-"
          start-placeholder="账单开始日期"
          end-placeholder="账单结束日期"
        >
        </el-date-picker>

        <el-select style="margin-left:20px" v-model="searchQuery.is_confirm" clearable size="small" placeholder="账单状态">
          <el-option v-for="(item,index) in typeArr" :key="index" :value="item.value" :label="item.label"></el-option>
        </el-select>

        <el-select style="margin-left:20px" v-model="searchQuery.receipt_status" clearable size="small" placeholder="开票状态">
          <el-option v-for="(item,index) in receiptList" :key="index" :value="item.value" :label="item.label"></el-option>
        </el-select>
      </div>
      <div>
        <el-button size="small" type="primary" @click="searchChange"
          >查询</el-button
        >
      </div>
    </div>
    <div class="line"></div>
    <div class="tableBlock">
      <el-table
        :data="tableData"
        style="width: 100%"
        :height="tableHeight"
        :border="true"
        v-tableHeight="{ bottomOffset: 74 }"
        :tableLoading="loading"
      >
        <el-table-column label="账单号" :show-overflow-tooltip="true" width="120" >
          <template slot-scope="scope">{{scope.row.bill_no | textFormat }}</template>
        </el-table-column>
        <el-table-column label="账单类型" :show-overflow-tooltip="true" width="110" >
          <template slot-scope="scope">{{scope.row.is_income==1?"客户付款":"平台退款" | textFormat }}</template>
        </el-table-column>
        <el-table-column label="账单日期" :show-overflow-tooltip="true" width="120" >
          <template slot-scope="scope">{{scope.row.bill_date | secondFormat("LL") }}</template>
        </el-table-column>
        <el-table-column label="开票抬头" :show-overflow-tooltip="true" width="150" >
          <template slot-scope="scope">{{scope.row.title | textFormat }}</template>
        </el-table-column>
        <el-table-column label="委托编号" :show-overflow-tooltip="true" width="150" >
          <template slot-scope="scope">{{scope.row.job_no | textFormat }}</template>
        </el-table-column>
        <el-table-column label="船名/航次" :show-overflow-tooltip="true" width="90" >
          <template slot-scope="scope">{{scope.row.vessel | textFormat }}/{{scope.row.voyage_flight | textFormat }}</template>
        </el-table-column>
        <el-table-column label="起运港" :show-overflow-tooltip="true">
          <template slot-scope="scope">{{scope.row.pol_name_en || scope.row.pol_name_zh | textFormat }}</template>
        </el-table-column>
        <el-table-column label="目的港" :show-overflow-tooltip="true" width="120" >
          <template slot-scope="scope">{{scope.row.pod_name_en||scope.row.pod_name_zh | textFormat }}</template>
        </el-table-column>
        <el-table-column label="账单状态" :show-overflow-tooltip="true" >
          <template slot-scope="scope"> {{scope.row.is_confirm == 1?"已确认":"未确认"}}</template>
        </el-table-column>
        <el-table-column label="开票状态" :show-overflow-tooltip="true" width="100" >
          <template slot-scope="scope">{{scope.row.receipt_status==0?"未开票":scope.row.receipt_status==1?"部分开票":"全部开票" | textFormat }}</template>
        </el-table-column>
        <el-table-column label="币种" :show-overflow-tooltip="true"     >
          <template slot-scope="scope">{{scope.row.currency_code | textFormat }}</template>
        </el-table-column>
        <el-table-column label="结算金额" :show-overflow-tooltip="true"     >
          <template slot-scope="scope">{{scope.row.include_amount | textFormat }}</template>
        </el-table-column>
        <el-table-column label="分币种合计" :show-overflow-tooltip="true"  width='140'   >
          <template slot-scope="scope">{{scope.row.extend | removeChar }}</template>
        </el-table-column>
        <el-table-column label="操作">
          <div slot-scope="scope">
            <el-button type="text" @click="gotoDetail(scope.row)"
              >查看</el-button
            >
          </div>
        </el-table-column>
      </el-table>
    </div>
    <div class="paginationBlock">
      <el-pagination
        @current-change="handleChange"
        :page-size="15"
        layout="total, prev, pager, next"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import portSelect from "~ymp/components/common/portSelect";

export default {
  name: "mybill",
  components: { portSelect },
  data() {
    return {
      default_logo:"https://ym-attached-file.oss-cn-shanghai.aliyuncs.com/miniProgram/ship.png?versionId=CAEQIxiBgMCgot3e0BciIDczYThjOWU5NzZlYzRmOTA4Yzk4Yjk0MDUwZWMxZmUz",
      loading: false,
      total: 0,
      currentPage: 1,
      tableHeight: 100,
      tableData: [],
      typeArr:[
        {value:0,label:"未确认"},
        {value:1,label:"已确认"},
      ],
      receiptList:[
        {value:0,label:"未开票"},
        {value:1,label:"部分开票"},
        {value:2,label:"全部开票"},
      ],
      query:{
        time:[],
      },
      searchQuery:{
        bill_no:"", // 账单号
        job_no:'', //订单号
        receipt_no:'',// 发票
        is_confirm:"",
        receipt_status:"",
        time:"",
      },
    };
  },
  mounted() {
    // let start_date = this.$moment().subtract(6, 'days').startOf('day')
    // let end_date = this.$moment().endOf('day')
    // this.searchQuery.time=[start_date,end_date]
    this.$log({
      type: 2,
      topic: "ymp.workbench",
      id: "ymp.workbench.myBill",
    });
    this.getList();
  },
  methods: {
    gotoDetail(row){
      this.$log({
        type: 1,
        topic: "ymp.workbench",
        id: "ymp.workbench.myBill.detail",
        content: JSON.stringify({
          id: row.id || ''
        }),
      });
      this.$router.push({
        query: {
          id: row.id,
        },
        path: "/console/myBill/detail",
      })
    },
    handleChange(e) {
      this.currentPage = e;
      this.getList();
    },
    async getList() {
      this.loading = true;
      let params = {
        limit: 15,
        start: this.currentPage - 1,
        calculation_object:this.USER_INFO.company_id,
        user_id:this.USER_ID,
        nopage:0,
        is_confirm:this.searchQuery.is_confirm,
        receipt_status:this.searchQuery.receipt_status,
        bill_no:this.searchQuery.bill_no,
        job_no:this.searchQuery.job_no,
        receipt_no:this.searchQuery.receipt_no,
      };
      if(this.searchQuery.time&&this.searchQuery.time.length){
        params.bill_date_start=this.$moment(this.searchQuery.time[0]).startOf('day').unix()
        params.bill_date_end=this.$moment(this.searchQuery.time[1]).endOf('day').unix()
      }
      let result = await this.$store.dispatch( //
        "baseConsole/my_bill_list",
        params
      );
      this.loading = false;
      if (result && result.success) {
        this.tableData = result.count ? result.data : [];
        this.total = result.count;
      }
    },
    searchChange() {
      this.$log({
        type: 1,
        topic: "ymp.workbench",
        id: "ymp.workbench.myBill.search",
        content: JSON.stringify({
          bill: this.searchChange.bill_no || '',
          no: this.searchChange.job_no || '',
          fp: this.searchChange.receipt_no || '',
          status: this.searchChange.is_confirm || '',
          invoice_status: this.searchChange.receipt_status || '',
          start_time: this.searchQuery.time&&this.searchQuery.time.length ? this.$moment(this.searchQuery.time[0]).startOf('day').unix() : '',
          end_time: this.searchQuery.time&&this.searchQuery.time.length ? this.$moment(this.searchQuery.time[1]).endOf('day').unix() : '',
        }),
      });
      this.tableData = [];
      this.currentPage = 1;
      this.getList();
    },
  },
  filters: {
    removeChar(obj){
      let str=""
      try {
        obj = JSON.parse(obj)
        obj.forEach(item => {
          str+=`${item.currency}：${item.include_tax}；`
        });
      } catch (error) {
        str="-"
      }
      return str
    }
  }
};
</script>
<style scoped lang="less">
.protMsg {
  display: flex;
  justify-content: space-between;
  .from {
    width: 100px;
    p{
      width: 100%;
    }
  }
  .jt {
    width: 50px;
    color: #027fff;
    line-height: 50px;
  }
  .dest {
    width: 100px;
    p{
      width: 100%;
    }
  }
}
.search {
  display: flex;
  width: 100%;
  align-items: center;
  padding: 10px;
  justify-content: space-between;

  .searchFl {
    width: 90%;
    display: flex;
    align-items: center;

    .searchInput {
      width: 15%;
      padding: 0 6px;
    }
  }
}
.line {
  height: 1px;
  width: 100%;
  background: #ccc;
  margin-bottom: 20px;
}
.shipping{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
</style>